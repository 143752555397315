import { Link } from 'gatsby';
import React from 'react';
import { Helmet } from 'react-helmet';

import logo from '../../../images/svg/logo.svg';

const Header = ({ path, children }) => {

  console.log('header', path);

  return (
    <>
      <Helmet>
        <link href="https://fonts.googleapis.com/css2?family=Nunito:wght@300;400;500;600;700;800;900&display=swap" rel="stylesheet" />

        {/*    <!-- Meta Pixel Code --> */}
        <script>{`
          !function(f,b,e,v,n,t,s)
          {if(f.fbq)return;n=f.fbq=function(){n.callMethod ?
            n.callMethod.apply(n, arguments) : n.queue.push(arguments)};
          if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
          n.queue=[];t=b.createElement(e);t.async=!0;
          t.src=v;s=b.getElementsByTagName(e)[0];
  s.parentNode.insertBefore(t,s)}(window, document,'script',
          'https://connect.facebook.net/en_US/fbevents.js');
          fbq('init', '478853527619948');
          fbq('track', 'PageView');
        `}
        </script>
        <noscript>
          {`<img height="1" width="1" style="display:none"
            src="https://www.facebook.com/tr?id=478853527619948&ev=PageView&noscript=1"
          />`}
        </noscript>
        {/* <!-- End Meta Pixel Code --> */}
      </Helmet>

      <nav className="bg-accent py-2 md:py-2">
        <div className="container px-4 mx-auto justify-between flex md:items-center">

          <div className="flex justify-between items-center">
            <Link to='/' className="font-bold text-xl text-indigo-600">
              <img className="h-10" src={logo} alt="Pró floripa logo" />
            </Link>
          </div>

          <div className="flex-col md:flex-row md:ml-auto mt-3 md:mt-0" id="navbar-collapse">
            <a href='https://api.whatsapp.com/send?phone=5548991491166&text=Ol%C3%A1.%20Gostaria%20de%20saber%20mais%20sobre%20Unisul%20Digital' target='_blank' className="font-bold text-xl text-white link primary">
              Fale conosco
            </a>
          </div>
        </div>
      </nav>
    </>
  )
}

export default Header
