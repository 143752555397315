import { Link } from 'gatsby'
import React from 'react'
import logo from '../../../images/svg/logo2.svg'

const Footer = ({ bgColor }) => {
  return (
    <footer className={`flex md:flex-row md:justify-evenly gap-y-5 flex-col justify-center p-10 px-3 
      ${bgColor ? 'bg-accent' : 'bg-white'}`}>
      <div className="px-4 text-center md:text-left">
        <img className="w-50 mx-auto" src={logo} alt="Unisul" />
      </div>
      <div className="px-4 text-center md:text-left">
        <h3 className="text-3xl font-bold text-gray-800">
          <span className="text-primary">Polo Florianópolis</span>
        </h3>
        <ul>
          <li className="text-primary py-2 flex justify-center items-center">
            <a href="https://api.whatsapp.com/send?phone=5548991491166" target="_blank" rel="noopener noreferrer" className='link' >
              (48) 99149-1166
            </a>
          </li>
          <li className="text-primary py-2 flex justify-center items-center">
            <a href="https://www.facebook.com/unisul.florianopolis" target="_blank" rel="noopener noreferrer" className='link' >
              Facebook
            </a>
          </li>
          <li className="text-primary py-2 flex justify-center items-center">
            <a href="https://instagram.com/unisul.florianopolis" target="_blank" rel="noopener noreferrer" className='link' >
              Instagram
            </a>
          </li>
        </ul>
      </div>

      <div className="px-4 text-center md:text-left">
        <h3 className="text-3xl font-bold text-gray-800">
          <span className="text-primary">Polo Palhoça</span>
        </h3>
        <ul>
          <li className="text-primary py-2 flex justify-center items-center">
            <a href="https://api.whatsapp.com/send?phone=5548991556061" target="_blank" rel="noopener noreferrer" className='link' >
              (48) 99155-6061
            </a>
          </li>
          <li className="text-primary py-2 flex justify-center items-center">
            <a href="https://facebook.com/unisul.palhocacentro" target="_blank" rel="noopener noreferrer" className='link' >
              Facebook
            </a>
          </li>
          <li className="text-primary py-2 flex justify-center items-center">
            <a href="https://www.instagram.com/unisul.palhoca" target="_blank" rel="noopener noreferrer" className='link' >
              Instagram
            </a>
          </li>
        </ul>
      </div>

      <div className="px-4 text-center md:text-left">
        <h3 className="text-3xl font-bold text-gray-800">
          <span className="text-primary">Polo Biguaçu</span>
        </h3>
        <ul>
          <li className="text-primary py-2 flex justify-center items-center">
            <a href="https://api.whatsapp.com/send?phone=5548991336903" target="_blank" rel="noopener noreferrer" className='link' >
              (48) 99133-6903
            </a>
          </li>
          <li className="text-primary py-2 flex justify-center items-center">
            <a href="https://facebook.com/unisul.biguacu" target="_blank" rel="noopener noreferrer" className='link' >
              Facebook
            </a>
          </li>
          <li className="text-primary py-2 flex justify-center items-center">
            <a href="https://www.instagram.com/unisul.biguacu" target="_blank" rel="noopener noreferrer" className='link' >
              Instagram
            </a>
          </li>
        </ul>
      </div>
    </footer>
  )
}

export default Footer
