import React from 'react'
import Header from './Header'
import Footer from './Footer'

const Layout = ({ children, footerColor }) => {
  return (
    <>
      <Header></Header>
      <main className="w-full relative scroll-smooth">
        {children}
      </main>
      <Footer bgColor={footerColor}></Footer>
    </>
  )
}

export default Layout
